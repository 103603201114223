import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

let state = {
    accessToken: null,
    systemData: null,
    user: null,
    layout: null,
    sidebar: null,
    isMyProfile: true,
    currentRoute: {},
    dashboards: [],
    events: [],
    marketLvl1: {},
    marketLvl2: {},
    marketLvl3: {},
    currentMarketLvl: 0,
    stockFilter: {
        name: "تعداد معامله",
        sort: 4,
        value: "trades.ZTotTran"
        },
    stockFilterFlow: {
        name: "همه",
        flow: 0,
        },
    marketHeader:{},
    stockHeader:{},
    currentTabMarket: 0,
    showStaticTab: false,
    globalCentrifugeData: {},
    globalCentrifugeChat: {},
    globalMarketModelHistory: {},
    adsStatic: false,
    adsData:null,
    objectMetrix:{},
    set: 0,
};

let getters = {
    wrState: state => key => {
        let value = state[key];
        value = JSON.parse(JSON.stringify(value));
        return value;
    }
};

let mutations = {

    setAdsStatic: (state, value) => {
        state.adsStatic = value;
    },
    setAdsData: (state, value) => {
        state.adsData = value;
    },
    updateGlobalMarketModelHistoryTimestamp: (state, value) => {
        state.globalMarketModelHistory[value].timestamp = Date.now();
    },
    pushGlobalMarketModelHistory: (state, value) => {
        state.globalMarketModelHistory[value.market_id] = value;
    },
    setGlobalCentrifugeData: (state, value) => {
        state.globalCentrifugeData = value;
    },
    setGlobalCentrifugeChat: (state, value) => {
        state.globalCentrifugeChat = value;
    },
    setShowStaticTab: (state, value) => {
        state.showStaticTab = value;
    },
    setCurrentTabMarket: (state, value) => {
        state.currentTabMarket = value;
    },
    setMarketHeader: (state, value) => {
        if (!value) return;
        state.marketHeader = value;
    },
    setStockHeader: (state, value) => {
        if (!value) return;
        state.stockHeader = value;
    },
    setStockFilterFlow: (state, value) => {
        if (!value) return;
        state.stockFilterFlow = value;
    },

    setStockFilter: (state, value) => {
        if (!value) return;
        state.stockFilter = value;
    },
    
    setMarketlayer: (state, value) => {
        if (!value) return;
        state.currentMarketLvl = value;
    },

    setMarketLvl1: (state, value) => {
        if (!value) return;
        state.marketLvl1 = value;
    },

    setMarketLvl2: (state, value) => {
        if (!value) return;
        state.marketLvl2 = value;
    },

    setMarketLvl3: (state, value) => {
        if (!value) return;
        state.marketLvl3 = value;
    },

    setAccessToken: (state, value) => {
        if (!value) return;

        localStorage.setItem('access_token', value);

        state.accessToken = value;

    },

    setSystemData: (state, data) => {
        if (!data) return;

        localStorage.setItem('system_data', JSON.stringify(data));

        state.systemData = data;
    },

    setUserData: (state, data) => {
        if (!data) return;

        if (data.avatar) data.avatar = JSON.parse(data.avatar);
        if (data.opts) data.opts = JSON.parse(data.opts);

        localStorage.setItem('user_data', JSON.stringify(data));

        state.user = data;
    },

    setLayout: (state, data) => {
        if (!data) return;

        localStorage.setItem('layout', data);

        state.layout = data;
    },

    setSidebar: (state, data) => {
        if (!data) return;

        localStorage.setItem('sidebar', data);

        state.sidebar = data;
    },

    routeChanged: (state, data) => {
        state.currentRoute = data;
    },

    setDashboards: (state, data) => {
        if (!data) return;

        localStorage.setItem('dashboards', JSON.stringify(data));

        state.dashboards = data;
    },

    addDashboard: (state, data) => {
        state.dashboards.push(data.dashboard);

        localStorage.setItem('dashboards', JSON.stringify(state.dashboards));
    },

    editDashboard: (state, data) => {
        state.dashboards = state.dashboards.filter((dashboard, index) => {
            if (dashboard.id == data.dashboard.id) {
                dashboard.title = data.dashboard.title;
            }

            return dashboard;
        });

        localStorage.setItem('dashboards', JSON.stringify(state.dashboards));
    },

    setDefaultDashboard: (state, data) => {
        state.dashboards.filter((dashboard, index) => {
            if (dashboard.id == data.default_id) {
                state.dashboards[index].default = true;
            } else {
                state.dashboards[index].default = false;
            }
        });

        localStorage.setItem('dashboards', JSON.stringify(state.dashboards));
    },

    removeDashboard: (state, data) => {
        state.dashboards.filter((dashboard, index) => {
            if (dashboard.id == data.id) {
                state.dashboards.splice(index, 1);
            }
        });

        localStorage.setItem('dashboards', JSON.stringify(state.dashboards));
    },

    setEvent: (state, data) => {
        state.events.push(data.name);
    },
    setObjectMetrix: (state, value) => {
        state.objectMetrix = value;
    },
};

let actions = {
    setSystemData: ({ commit }, opts = {}) => {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem('system_data') && !opts.forced) {
                let data = JSON.parse(localStorage.getItem('system_data'));
                commit('setSystemData', data);

                resolve();
            } else {
                Vue.prototype.$helpers.makeRequest('GET', '/core/system-data').then(api_response => {
                    if (api_response.status == 200) {
                        let data = api_response.data.response;
                        commit('setSystemData', data);

                        resolve();
                    }
                });
            }
        });
    },

    setUserData: ({ commit }, opts = {}) => {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem('user_data') && !opts.forced) {
                let data = JSON.parse(localStorage.getItem('user_data'));
                commit('setUserData', data);

                resolve();
            } else {

                Vue.prototype.$helpers.makeRequest('POST', '/user/me').then(api_response => {
                    if (api_response.status == 200) {
                        let data = api_response.data;
                        commit('setUserData', data);

                        resolve();
                    }
                });
            }
        });
    },

    setFirebaseToken: ({ commit }, opts = {}) => {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem('InternalNotificationToken')) {
                Vue.prototype.$helpers.makeRequest('GET', `/user/desktop-push-token?key=${localStorage.getItem('InternalNotificationToken')}`).then(api_response => {
                    // ----
                });
            }
            resolve();
        });
    },

    setLayout: ({ commit }, opts = {}) => {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem('layout')) {
                let data = localStorage.getItem('layout');

                Vue.prototype.$helpers.prepare_layout(data).then(data => {
                    resolve();
                });
            } else {
                Vue.prototype.$helpers.makeRequest('GET', '/app/tgju-layout').then(api_response => {
                    if (api_response.status == 200) {
                        let data = Vue.prototype.$helpers.firebase_conflict_solver(api_response.data.layout);

                        commit('setLayout', data);
                        Vue.prototype.$helpers.prepare_layout(data).then(data => {
                            resolve();
                        });
                    }
                });
            }
        });
    },

    setSidebar: ({ commit }, opts = {}) => {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem('sidebar')) {
                let data = localStorage.getItem('sidebar');

                commit('setSidebar', data);

                resolve();
            } else {
                Vue.prototype.$helpers.makeRequest('GET', '/app/tgju-sidebar').then(api_response => {
                        if (api_response.status == 200) {
                        let data = api_response.data.sidebar;

                        commit('setSidebar', data);

                        resolve();
                    }
                });
            }
        });
    },

    setDashboards: ({ commit }, opts = {}) => {

        return new Promise((resolve, reject) => {
            if (localStorage.getItem('dashboards') && !opts.forced) {
                let data = localStorage.getItem('dashboards');

                commit('setDashboards', data);

                resolve();
            } else {
                Vue.prototype.$helpers.makeRequest('GET', '/dashboard/list').then(api_response => {
                    if (api_response.status == 200) {
                        let dashboards = api_response.data.response.dashboards;

                        commit('setDashboards', dashboards);

                        resolve();
                    }
                });
            }
        });
    },

    setEvent: ({ commit, state }, name) => {
        return new Promise((resolve, reject) => {
            if (state.events.includes(name)) {
                resolve(false);
            } else {
                commit('setEvent', { name });
                resolve(true);
            }
        });
    },
};

const store = new Vuex.Store({
    // strict: process.env.NODE_ENV !== 'production',
    state,
    getters,
    mutations,
    actions,
});
// TODO: موقت عملیات سیو کردن توکن فایربیس در دیتابیس را انجام می دهد
window.store = store;
export default store;