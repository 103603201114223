import Vue from 'vue'
import Vuetify from 'vuetify'
// import 'vuetify/dist/vuetify.min.css'
import globalJS from '../plugins/global';

if(process.env.VUE_APP_ENV == 'local' && window.AppLayout != 'web') {
    if (globalJS.checkDevice('app')) {
        require('vuetify/dist/vuetify.min.css')
    }
}

Vue.use(Vuetify)

const opts = {
    theme: { 
        dark: false,
    },
    rtl: true,
}

export default new Vuetify(opts)