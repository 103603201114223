<template>
    <div 
        class="tgju-dashboard-panel"
        :class="{
            ' show-ads ' : this.$store.state.adsStatic,
            ' show-ads2 ' : this.$store.state.adsStaticTop,
        }"


        data-app app-data="true">
        <div class="dashboard-main-box">
            <RightNav />
            <div class="dashboard-dev dashboard-main sidebar-set-full bootstrap-fix widgets" data-target="main-menu">
                <Header v-if="hasHeader && !is_Mobile && !is_Desktop" />
                <HeaderMobile v-if="(hasHeader && is_Mobile) || (hasHeader && is_Desktop)" />
                <router-view :key="$route.path" />
                <div class="hover-card-popper"></div>
            </div>
            <Modal />
            <Modal2 />
            <Modal3 />
            <Helps />
            <MobileLayout v-if="is_Mobile" />
    
            <div class="update-alert" v-if="false && (is_Mobile || is_Desktop) && !is_app_update && hide_app_update">
                <div class="update-alert-box-bg"></div>
                <div class="update-alert-box">
                    <div class="update-alert-title">
                        <i class="uil uil-exclamation-circle"></i>
                        <span>آپدیت شماره {{ static_app_update_alert.version }} !</span>
                    </div>
                    <div class="update-alert-content">
                        <p>نمونه توضیحات تکمیلی در باره اطلاعیه جاری برای تست نمایش در اپلیکیشن ها ...</p>
                        <ul>
                            <li v-for="(item, index) in static_app_update_alert.items" :key="index">{{ item.name }}</li>
                        </ul>
                        <div class="update-alert-btn" @click.stop="hide_update">
                            <div class="update-alert-btn-item">
                                تایید و بستن
                            </div>
                        </div>
                    </div>
    
                </div>
            </div>
    
    
        </div>
    </div>
    </template>
    
    <style lang="scss">
    @import "@/styles/app.scss";
    @import "@/styles/components/general.scss";
    @import "@/styles/trades.scss";
    </style>
    
    <script>
    let interval = setInterval(() => {
        if ($('.bar-menu-dashboard').length) {
            clearInterval(interval);
    
            let script = document.createElement("script");
            script.src = process.env.VUE_APP_URL + '/app.js';
            document.body.appendChild(script);
        }
    }, 100);
    
    
    import "@/plugins/filters"
    import RightNav from '@/views/partials/RightNav.vue'
    import Header from '@/views/partials/Header.vue'
    import HeaderMobile from '@/views/mobile/partials/Header.vue'
    import Modal from '@/views/partials/Modal.vue'
    import Modal2 from '@/views/partials/Modal2.vue'
    import Modal3 from '@/views/partials/Modal3.vue'
    import Helps from '@/views/partials/Helps.vue'
    import MobileLayout from '@/views/mobile/MobileLayout.vue'
    import Centrifuge from "centrifuge"
    import static_app_update_alert from "@/assets/static_app_update_alert";
    
    export default {
        name: 'App',
    
        components: {
            RightNav,
            Header,
            HeaderMobile,
            Modal,
            Modal2,
            Modal3,
            Helps,
            MobileLayout
        },
        data: function () {
            return {
                is_Mobile: false,
                is_Desktop: false,
                static_app_update_alert,
                hide_app_update: true,
            }
        },
        mounted() {
            this.socketMarket(this.$store);
            // this.socketChat(this.$store);
            if (Vue.prototype.$helpers.checkDevice('mobile')) {
                this.is_Mobile = true;
            }else if (Vue.prototype.$helpers.checkDevice('desktop')) {
                this.is_Desktop = true;
            }else{
                this.is_Mobile = false;
            }
            this.setAdsData();
        },
        computed: {
            is_app_update: function () {
                return window.localStorage.getItem("app_version") === this.static_app_update_alert.version;
            },
            hasHeader: function () {
                return (typeof this.$store.state.currentRoute.meta != 'undefined') ? this.$store.state.currentRoute.meta.header : false;
            },
        },
        methods: {
            setAdsData(){
                $.ajax({
                    'url': 'https://api.tgju.org/v1/advertisement/place?places=18,32',
                    'success': (result) => {
                        if (result && result.response && result.response.length) {
                            var results = {};
                            for(var i in result.response) {
                                results[result.response[i]['place_id']] = ( { 
                                    "title" : result.response[i]['title'],
                                    "image" : result.response[i]['image'],
                                    "link" : result.response[i]['link']
                                } );
    
                            }
                            this.$store.commit('setAdsData', results);
                        }
                    }
                });
            },
            hide_update() {
                window.localStorage.setItem("app_version", this.static_app_update_alert.version);
                this.hide_app_update = false;
            },
            socketMarket(store) {
                // TODO: نیاز به تغییر آی پی دارد
                var centrifuge = new Centrifuge("wss://stream.tgju.org/connection/websocket");
                // centrifuge.setToken("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NjcyMDM2MTZ9.-RyDS9BOaoLd9iCz9T9_YOxTizVp8CW05QqKfuYiVT0");
                centrifuge.subscribe("tgju:stream", (ctx) => {
                    store.commit('setGlobalCentrifugeData', ctx.data);
                });
                centrifuge.subscribe("tgju:chat", function(ctx) {
                    store.commit('setGlobalCentrifugeChat', ctx.data);
                });
    
                centrifuge.on('connect', function(ctx) {
                    console.info('WebSocket connection established (latency: ' + ctx.latency + ')');
                });
    
                centrifuge.on('disconnect', function(ctx) {
                    console.warn('WebSocket connection closed');
                });
    
                centrifuge.connect();
            },
        },
    }
    </script>
    