import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import globalJS from "../plugins/global";

Vue.use(VueRouter);
var customRoute = "";
if (globalJS.checkDevice("app")) {
  customRoute = "mobile/";
}
const routes = [
  {
    path: "/",
    name: "Home",
    component: {
      beforeRouteEnter(to, from, next) {
        if (globalJS.checkDevice("app")) {
          next({ path: "/homeintro" });
        } else {
          next({ path: "/profile" });
        }
      },
    },
    meta: { header: false, requiresAuth: true, title: "پنل کاربری" },
  },
  {
    path: "/crypto",
    name: "Crypto",
    component: () => import("../views/Crypto/Crypto.vue"),
    meta: {
      header: false,
      editable: false,
      requiresAuth: true,
      title: "نمای کلی ارزهای دیجیتال",
    },
  },
  {
    path: "/exchange/dashboard",
    name: "ExchangeDashboard",
    component: () => import("../views/" + customRoute + "Trades/dashboard.vue"),
    meta: {
      header: false,
      editable: false,
      requiresAuth: true,
      title: "داشبورد معاملات",
    },
  },
  {
    path: "/exchange/markets",
    name: "ExchangeMarkets",
    component: () => import("../views/" + customRoute + "Trades/markets.vue"),
    meta: {
      header: false,
      editable: false,
      requiresAuth: true,
      title: "بازارها",
    },
  },
  {
    path: "/exchange/wallet",
    name: "ExchangeWallet",
    component: () => import("../views/" + customRoute + "Trades/wallet.vue"),
    meta: {
      header: false,
      editable: false,
      requiresAuth: true,
      title: "کیف پول",
    },
  },
  {
    path: "/exchange/wallet/deposit",
    name: "ExchangeWalletDeposit",
    component: () => import("../views/" + customRoute + "Trades/deposit.vue"),
    meta: {
      header: false,
      editable: false,
      requiresAuth: true,
      title: "واریز",
    },
  },
  {
    path: "/exchange/wallet/withdraw",
    name: "ExchangeWalletWithdraw",
    component: () => import("../views/" + customRoute + "Trades/withdraw.vue"),
    meta: {
      header: false,
      editable: false,
      requiresAuth: true,
      title: "برداشت",
    },
  },
  {
    path: "/exchange/transactions",
    name: "ExchangeWalletTransactions",
    component: () => import("../views/" + customRoute + "Trades/transactions.vue"),
    meta: {
      header: false,
      editable: false,
      requiresAuth: true,
      title: "تاریخچه",
    },
  },  
  {
    path: "/exchange/wallet/bank-accounts",
    name: "ExchangeWalletBankAccounts",
    component: () => import("../views/" + customRoute + "Trades/bankAccounts.vue"),
    meta: {
      header: false,
      editable: false,
      requiresAuth: true,
      title: "حساب‌های بانکی",
    },
  },
  {
    path: "/exchange/trade/simple",
    name: "TradeSimple",
    component: () => import("../views/" + customRoute + "Trades/dashboard.vue"),
    meta: {
      header: false,
      editable: false,
      requiresAuth: true,
      title: "معامله",
    },
  },
  {
    path: "/exchange/trade",
    name: "Trade",
    component: () => import("../views/" + customRoute + "Trades/dashboard.vue"),
    meta: {
      isFullView: true,
      header: false,
      editable: false,
      requiresAuth: true,
      title: "معامله حرفه‌ای",
    },
  },
  {
    path: "/exchanges/currency-exchange/:type?",
    name: "CurrencyExchange",
    component: () =>
      import("../views/" + customRoute + "Exchanges/CurrencyExchange.vue"),
    meta: {
      header: false,
      editable: false,
      requiresAuth: true,
      title: "قیمت خرید در صرافی‌های ارزی",
    },
  },
  {
    path: "/exchanges/currency-exchange/:id/:slug",
    name: "CurrencyExchangeProfile",
    component: () =>
      import(
        "../views/" + customRoute + "Exchanges/CurrencyExchangeProfile.vue"
      ),
    meta: {
      header: false,
      editable: false,
      requiresAuth: true,
      title: "پروفایل صرافی‌های ارزی",
    },
  },
  {
    path: "/exchanges/local/:type?",
    name: "LocalExchanges",
    component: () =>
      import("../views/" + customRoute + "Exchanges/LocalExchanges.vue"),
    meta: {
      header: false,
      editable: false,
      requiresAuth: true,
      title: "قیمت فروش در صرافی‌های داخلی ارز دیجیتال",
    },
  },
  {
    path: "/exchanges/local/:id/:slug",
    name: "LocalExchangeProfile",
    component: () =>
      import("../views/" + customRoute + "Exchanges/LocalExchangeProfile.vue"),
    meta: {
      header: false,
      editable: false,
      requiresAuth: true,
      title: "پروفایل صرافی‌های داخلی ارز دیجیتال",
    },
  },
  {
    path: "/exchanges/global",
    name: "GlobalExchanges",
    component: () =>
      import("../views/" + customRoute + "Exchanges/GlobalExchanges.vue"),
    meta: {
      header: false,
      editable: false,
      requiresAuth: true,
      title: "قیمت خرید در صرافی‌های جهانی ارز دیجیتال",
    },
  },
  {
    path: "/exchanges/global/:id/:slug",
    name: "GlobalExchangeProfile",
    component: () =>
      import("../views/" + customRoute + "Exchanges/GlobalExchangeProfile.vue"),
    meta: {
      header: false,
      editable: false,
      requiresAuth: true,
      title: "  پروفایل صرافی‌های جهانی ارز دیجیتال",
    },
  },
  {
    path: "/economics/country-list/acc",
    name: "EconomicsCountryListCategory",
    component: () =>
      import("../views/" + customRoute + "Economics/CountryListCatergory.vue"),
    meta: {
      header: false,
      editable: false,
      requiresAuth: true,
      title: "دادهای شاخص",
    },
  },
  {
    path: "/economics/country-list/:category/:type?",
    name: "EconomicsCountryList",
    component: () =>
      import("../views/" + customRoute + "Economics/CountryList.vue"),
    meta: {
      header: false,
      editable: false,
      requiresAuth: true,
      title: "دادهای شاخص",
    },
  },
  {
    path: "/economics/atlas/:country?",
    name: "EconomicsAtlas",
    component: () => import("../views/" + customRoute + "Economics/Atlas.vue"),
    meta: {
      header: false,
      editable: false,
      requiresAuth: true,
      title: "کشورها",
    },
  },
  {
    path: "/dashboards",
    name: "Dashboards",
    component: () =>
      import("../views/" + customRoute + "Dashboard/DashboardList.vue"),
    meta: {
      header: false,
      editable: false,
      requiresAuth: true,
      title: "مدیریت داشبورد‌ها",
    },
  },
  {
    path: "/dashboard/:id",
    name: "Dashboard",
    component: () =>
      import("../views/" + customRoute + "Dashboard/Dashboard.vue"),
    meta: {
      header: false,
      editable: false,
      requiresAuth: true,
      title: "داشبورد",
    },
  },
  {
    path: "/dashboard/edit/:id",
    name: "DashboardEdit",
    component: () =>
      import("../views/" + customRoute + "Dashboard/DashboardEdit.vue"),
    meta: {
      header: false,
      editable: true,
      requiresAuth: true,
      title: "ویرایش داشبورد",
    },
  },
  {
    path: "/alert",
    name: "AlertsView",
    component: () => import("../views/Alerts.vue"),
    meta: { requiresAuth: true, title: "هشدار‌ها" },
  },
  {
    path: "/widgets",
    name: "WidgetsView",
    component: () => import("../views/Widgets.vue"),
    meta: { requiresAuth: true, title: "ابزارک‌ها" },
  },
  {
    path: "/bookmark",
    name: "FavoritesView",
    component: () => import("../views/" + customRoute + "Favorites.vue"),
    meta: { requiresAuth: true, title: "بوک‌مارک‌ها" },
  },
  {
    path: "/todo",
    name: "TodoView",
    component: () => import("../views/" + customRoute + "Todo.vue"),
    meta: { requiresAuth: true, title: "وظایف روزانه" },
  },
  {
    path: "/notes",
    name: "NotesView",
    component: () => import("../views/" + customRoute + "Notes.vue"),
    meta: { requiresAuth: true, title: "یادداشت‌ها و اسناد" },
  },
  {
    path: "/watchlist",
    name: "WatchListsView",
    component: () => import("../views/" + customRoute + "WatchLists.vue"),
    meta: { requiresAuth: true, title: "واچ لیست‌ها" },
  },
  {
    path: "/calendar-table",
    name: "CalendarTable",
    component: () => import("../views/CalendarTable.vue"),
    meta: { requiresAuth: true, title: "جدول تقویم" },
  },
  {
    path: "/calendar-view",
    name: "CalendarView",
    component: () => import("../views/CalendarView.vue"),
    meta: { requiresAuth: true, title: "تقویم اقتصادی" },
  },
  {
    path: "/technical",
    name: "NewTechnical",
    component: () => import("../views/" + customRoute + "Technicals.vue"),
    meta: { requiresAuth: true, title: "تحلیل‌ها" },
  },
  {
    path: "/technical/add",
    name: "AddTechnical",
    component: () => import("../views/NewTechnical.vue"),
    meta: { requiresAuth: true, title: "افزودن تحلیل جدید" },
  },
  {
    path: "/analysts",
    name: "PublicAnalysts",
    component: () => import("../views/" + customRoute + "PublicAnalysts.vue"),
    meta: { requiresAuth: false, title: "تحلیل‌گرها" },
  },
  {
    path: "/technicals",
    name: "PublicTechnical",
    component: () => import("../views/" + customRoute + "PublicTechnical.vue"),
    meta: { requiresAuth: false, title: "تابلو تحلیل‌ها" },
  },
  {
    path: "/technical/view/:id",
    name: "ViewTechnical",
    component: () => import("../views/" + customRoute + "ViewTechnical.vue"),
    meta: { requiresAuth: false, title: "مشاهده تحلیل تکنیکال" },
  },
  {
    path: "/investment",
    name: "Investment",
    component: () => import("../views/Investment/General.vue"),
    meta: { requiresAuth: true, title: "سبد دارایی" },
  },
  {
    path: "/investment/inventory",
    name: "InvestmentInventory",
    component: () => import("../views/Investment/Inventory.vue"),
    meta: { requiresAuth: true, title: "دارایی‌ها" },
  },
  {
    path: "/investment/sell-history",
    name: "InvestmentSellHistory",
    component: () => import("../views/Investment/SellHistory.vue"),
    meta: { requiresAuth: true, title: "تاریخچه فروش" },
  },
  {
    path: "/investment/buy-history",
    name: "InvestmentBuyHistory",
    component: () => import("../views/Investment/BuyHistory.vue"),
    meta: { requiresAuth: true, title: "تاریخچه خرید" },
  },
  {
    path: "/investment/watch",
    name: "InvestmentWatch",
    component: () => import("../views/Investment/Watch.vue"),
    meta: { requiresAuth: true, title: "نرخ‌های سرمایه‌گذاری" },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/" + customRoute + "Profile/View.vue"),
    meta: { header: true, requiresAuth: true },
  },
  {
    path: "/profile/edit",
    name: "ProfileEdit",
    component: () => import("../views/" + customRoute + "Profile/Edit.vue"),
    meta: { header: true, requiresAuth: true, title: "ویرایش حساب کاربری" },
  },
  {
    path: "/profile/technical",
    name: "ProfileTechnical",
    component: () =>
      import("../views/" + customRoute + "Profile/Technical.vue"),
    meta: { header: true, requiresAuth: true, title: "تحلیل‌های من" },
  },
  {
    path: "/profile/following",
    name: "ProfileFollowing",
    component: () => import("../views/Profile/Following.vue"),
    meta: { header: true, requiresAuth: true, title: "دنبال‌شوندگان من" },
  },
  {
    path: "/profile/followers",
    name: "ProfileFollowers",
    component: () => import("../views/Profile/Followers.vue"),
    meta: { header: true, requiresAuth: true, title: "دنبال‌کنندگان من" },
  },
  {
    path: "/profile/_/:username",
    name: "ProfileOthers",
    component: () =>
      import("../views/" + customRoute + "ProfileOthers/View.vue"),
    meta: { header: true, requiresAuth: false, title: "نمایه کاربر" },
  },
  {
    path: "/profile/_/:username/technical",
    name: "ProfileTechnicalOthers",
    component: () =>
      import("../views/" + customRoute + "ProfileOthers/Technical.vue"),
    meta: { header: true, requiresAuth: false, title: "تحلیل‌های کاربر" },
  },
  {
    path: "/profile/_/:username/following",
    name: "ProfileFollowingOthers",
    component: () => import("../views/ProfileOthers/Following.vue"),
    meta: { header: true, requiresAuth: false, title: "دنبال‌شوندگان کاربر" },
  },
  {
    path: "/profile/_/:username/followers",
    name: "ProfileFollowersOthers",
    component: () => import("../views/ProfileOthers/Followers.vue"),
    meta: { header: true, requiresAuth: false, title: "دنبال‌کنندگان کاربر" },
  },
  {
    path: "/comment",
    name: "Comment",
    component: () => import("../views/TgjuPosts.vue"),
    meta: {
      header: false,
      editable: false,
      requiresAuth: false,
      title: "نظرات",
    },
  },
  {
    path: "/tgju-forum-technicals",
    name: "TgjuForumTechnicals",
    component: () => import("../views/TgjuForumTechnicals.vue"),
    meta: {
      header: false,
      editable: false,
      requiresAuth: false,
      title: "تابلو تحلیل‌ها",
    },
  },
  {
    path: "/forums",
    name: "Forums",
    component: () => import("../views/" + customRoute + "Forums.vue"),
    meta: { header: false, requiresAuth: true, title: "اتاق‌های گفت‌و‌گو" },
  },
  {
    path: "/homeintro",
    name: "HomeIntro",
    component: () => import("../views/" + customRoute + "Home.vue"),
    meta: { header: false, requiresAuth: true, title: "خانه" },
  },
  {
    path: "/chat",
    name: "Chat",
    component: () => import("../views/" + customRoute + "pages/Chat.vue"),
    meta: { header: false, requiresAuth: true, title: "پشتیبانی آنلاین" },
  },
  {
    path: "/smartchat",
    name: "SmartChat",
    component: () => import("../views/" + customRoute + "pages/SmartChat.vue"),
    meta: { header: false, requiresAuth: true, title: "پشتیبانی هوشمند" },
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/" + customRoute + "pages/Contact.vue"),
    meta: { header: false, requiresAuth: true, title: "تماس با ما" },
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("../views/" + customRoute + "pages/Terms.vue"),
    meta: { header: false, requiresAuth: true, title: "قوانین" },
  },
  {
    path: "/report",
    name: "Report",
    component: () => import("../views/" + customRoute + "pages/Report.vue"),
    meta: { header: false, requiresAuth: true, title: "گزارش خطا" },
  },
  {
    path: "/social",
    name: "Social",
    component: () => import("../views/" + customRoute + "pages/Social.vue"),
    meta: { header: false, requiresAuth: true, title: "شبکه‌های اجتماعی" },
  },
  {
    path: "/robot",
    name: "Robot",
    component: () => import("../views/" + customRoute + "pages/Robot.vue"),
    meta: { header: false, requiresAuth: true, title: "ربات‌های پاسخگو" },
  },
  {
    path: "/stream",
    name: "Stream",
    component: () => import("../views/" + customRoute + "pages/Stream.vue"),
    meta: { header: false, requiresAuth: true, title: "پخش‌های زنده" },
  },
  {
    path: "/tools",
    name: "ToolsIntro",
    component: () => import("../views/" + customRoute + "Tools.vue"),
    meta: { header: false, requiresAuth: true, title: "ابزار‌ها" },
  },
  {
    path: "/tools/:tools_type",
    name: "ToolsViews",
    component: () => import("../views/" + customRoute + "Tools.vue"),
    meta: { header: false, requiresAuth: true, title: "ابزار‌ها" },
  },
  {
    path: "/tools/:tools_type/:page",
    name: "ToolsViewsList",
    component: () => import("../views/" + customRoute + "ToolsPage.vue"),
    meta: { header: false, requiresAuth: true, title: "ابزار‌ها" },
  },
  {
    path: "/market",
    name: "MarketRedirect",
    redirect: { name: "MarketViews", params: { market_type: "local-markets" } },
  },
  {
    path: "/market/:market_type",
    name: "MarketViews",
    component: () => import("../views/" + customRoute + "Market.vue"),
    meta: { header: false, requiresAuth: true },
    children: [
      {
        path: "list/:market_id",
        name: "MarketList",
        component: () =>
          import("../views/" + customRoute + "Market/Dynamic.vue"),
      },
    ],
  },
  {
    path: "/retail/retail-market",
    name: "RetailViews",
    component: () => import("../views/" + customRoute + "Retail.vue"),
    meta: { header: false, requiresAuth: true },
    children: [
      {
        path: "list/:retail_id",
        name: "RetailList",
        component: () =>
          import("../views/" + customRoute + "Retail/Retail.vue"),
        children: [
          {
            path: "subCategory/:subcategory_id",
            name: "subCategoryList",
            component: () =>
              import("../views/" + customRoute + "Retail/Branch.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/market/page/:name",
    name: "MarketStatic",
    component: () => import("../views/" + customRoute + "Market/Static.vue"),
    meta: { header: false, requiresAuth: true },
  },
  {
    path: "/market/profile/:name/overview",
    name: "MarketProfile",
    component: () =>
      import("../views/" + customRoute + "Market/Profile/Overview.vue"),
    meta: { header: false, requiresAuth: true, title: "در یک نگاه" },
  },
  {
    path: "/market/profile/:name/today",
    name: "MarketToday",
    component: () =>
      import("../views/" + customRoute + "Market/Profile/Today.vue"),
    meta: { header: false, requiresAuth: true, title: "شاخص در روز جاری" },
  },
  {
    path: "/market/profile/:name/history",
    name: "MarketHistory",
    component: () =>
      import("../views/" + customRoute + "Market/Profile/History.vue"),
    meta: { header: false, requiresAuth: true, title: "تاریخچه" },
  },
  {
    path: "/market/profile/:name/performance",
    name: "MarketPerformance",
    component: () =>
      import("../views/" + customRoute + "Market/Profile/Performance.vue"),
    meta: { header: false, requiresAuth: true, title: "عملکرد" },
  },
  {
    path: "/market/profile/:name/technical",
    name: "MarketTechnical",
    component: () =>
      import("../views/" + customRoute + "Market/Profile/Technical.vue"),
    meta: { header: false, requiresAuth: true, title: "تکنیکال" },
  },
  {
    path: "/market/profile/:name/indicator",
    name: "MarketIndicator",
    component: () =>
      import("../views/" + customRoute + "Market/Profile/Indicator.vue"),
    meta: { header: false, requiresAuth: true, title: "اندیکاتورها" },
  },
  {
    path: "/market/profile/:name/charts",
    name: "MarketCharts",
    component: () =>
      import("../views/" + customRoute + "Market/Profile/Charts.vue"),
    meta: { header: false, requiresAuth: true, title: "نمودارها" },
  },
  {
    path: "/market/profile/:name/news",
    name: "MarketNews",
    component: () =>
      import("../views/" + customRoute + "Market/Profile/News.vue"),
    meta: { header: false, requiresAuth: true, title: "اخبار و گزارشات" },
  },
  {
    path: "/market/profile/:name/profitloss",
    name: "MarketProfitloss",
    component: () =>
      import("../views/" + customRoute + "Market/Profile/Profitloss.vue"),
    meta: { header: false, requiresAuth: true, title: "سود و زیان" },
  },
  {
    path: "/market/profile/:name/archive",
    name: "MarketArchive",
    component: () =>
      import("../views/" + customRoute + "Market/Profile/Archive.vue"),
    meta: { header: false, requiresAuth: true, title: "آرشیو" },
  },

  {
    path: "/market/:type/profile/:name/overview",
    name: "StockProfileOverview",
    component: () =>
      import("../views/" + customRoute + "Market/Profile/Stock/Overview.vue"),
    meta: { header: false, requiresAuth: true, title: "در یک نگاه" },
  },
  {
    path: "/market/:type/profile/:name/history",
    name: "StockProfileHistory",
    component: () =>
      import("../views/" + customRoute + "Market/Profile/Stock/History.vue"),
    meta: { header: false, requiresAuth: true, title: "تاریخچه" },
  },
  {
    path: "/market/:type/profile/:name/technical",
    name: "StockProfileTechnical",
    component: () =>
      import("../views/" + customRoute + "Market/Profile/Stock/Technical.vue"),
    meta: { header: false, requiresAuth: true, title: "تکنیکال" },
  },
  {
    path: "/market/:type/profile/:name/indicator",
    name: "StockProfileIndicator",
    component: () =>
      import("../views/" + customRoute + "Market/Profile/Stock/Indicator.vue"),
    meta: { header: false, requiresAuth: true, title: "اندیکاتورها" },
  },
  {
    path: "/market/:type/profile/:name/charts",
    name: "StockProfileCharts",
    component: () =>
      import("../views/" + customRoute + "Market/Profile/Stock/Charts.vue"),
    meta: { header: false, requiresAuth: true, title: "نمودارها" },
  },
  {
    path: "/market/:type/profile/:name/news",
    name: "StockProfileNews",
    component: () =>
      import("../views/" + customRoute + "Market/Profile/Stock/News.vue"),
    meta: { header: false, requiresAuth: true, title: "اطلاعیه‌ها" },
  },
  {
    path: "/market/:type/profile/:name/eps",
    name: "StockProfileEps",
    component: () =>
      import("../views/" + customRoute + "Market/Profile/Stock/Eps.vue"),
    meta: { header: false, requiresAuth: true, title: "EPS" },
  },
  {
    path: "/market/:type/profile/:name/capital",
    name: "StockProfileCapital",
    component: () =>
      import("../views/" + customRoute + "Market/Profile/Stock/Capital.vue"),
    meta: { header: false, requiresAuth: true, title: "افزایش سرمایه" },
  },
  {
    path: "/market/:type/profile/:name/balancesheet",
    name: "StockProfileBalancesheet",
    component: () =>
      import(
        "../views/" + customRoute + "Market/Profile/Stock/Balancesheet.vue"
      ),
    meta: { header: false, requiresAuth: true, title: "ترازنامه" },
  },
  {
    path: "/market/:type/profile/:name/profitloss",
    name: "StockProfileProfitloss",
    component: () =>
      import("../views/" + customRoute + "Market/Profile/Stock/Profitloss.vue"),
    meta: { header: false, requiresAuth: true, title: "سود و زیان" },
  },
  {
    path: "/market/:type/profile/:name/cashflow",
    name: "StockProfileCashflow",
    component: () =>
      import("../views/" + customRoute + "Market/Profile/Stock/Cashflow.vue"),
    meta: { header: false, requiresAuth: true, title: "گردش وجوه نقد" },
  },
  {
    path: "/market/:type/profile/:name/financialratios",
    name: "StockProfileFinancialratios",
    component: () =>
      import(
        "../views/" + customRoute + "Market/Profile/Stock/Financialratios.vue"
      ),
    meta: { header: false, requiresAuth: true, title: "نسبت‌های مالی" },
  },
  {
    path: "/market/:type/profile/:name/category",
    name: "StockProfileCategory",
    component: () =>
      import("../views/" + customRoute + "Market/Profile/Stock/Category.vue"),
    meta: { header: false, requiresAuth: true, title: "نمادهای هم‌گروه" },
  },
  {
    path: "/market/:type/profile/:name/specification",
    name: "StockProfileSpecification",
    component: () =>
      import(
        "../views/" + customRoute + "Market/Profile/Stock/Specification.vue"
      ),
    meta: { header: false, requiresAuth: true, title: "مشخصات" },
  },
  {
    path: "/market/:type/profile/:name/nav",
    name: "StockProfileNav",
    component: () =>
      import("../views/" + customRoute + "Market/Profile/Stock/Nav.vue"),
    meta: { header: false, requiresAuth: true, title: "روند NAV" },
  },

  {
    path: "/news",
    name: "NewsIntro",
    component: () => import("../views/" + customRoute + "News/Home.vue"),
    meta: { header: false, requiresAuth: false, title: "اخبار" },
  },
  {
    path: "/news/:category_id",
    name: "ApplicationNews",
    component: () => import("../views/" + customRoute + "News/News.vue"),
    meta: { header: false, requiresAuth: false, title: "اخبار" },
  },
  {
    path: "/news/content/:id",
    name: "ApplicationNewsContent",
    component: () => import("../views/" + customRoute + "News/NewsContent.vue"),
    meta: { header: false, requiresAuth: false, title: "اخبار" },
  },
  {
    path: "/app/versions",
    name: "ApplicationVersions",
    component: () => import("../views/" + customRoute + "Versions.vue"),
    meta: { header: false, requiresAuth: false, title: "ورژن‌ها" },
  },
  {
    path: "/auth/login",
    name: "Login",
    component: () => import("../views/LoginRegisterView.vue"),
    meta: { title: "ورود - ثبت‌نام" },
  },
  {
    path: "/auth/sso",
    name: "AuthSso",
    component: {
      beforeRouteEnter(to, from, next) {
        store.commit("setAccessToken", to.query.token);
        store.dispatch("setUserData").then((response) => {
          // سینک کردن منو های داشبورد از دیتابیس
          store.dispatch("setDashboards", { forced: true });
          // ثبت کد پوش نوتیفیکیشن برای کاربر
          store.dispatch("setFirebaseToken", { forced: true });
          //---------------
          if (window.location.href.indexOf("popup=true") !== -1) {
            window.close();
          } else {
            next({ path: "/profile" });
          }
        });
      },
    },
  },
  {
    path: "/auth/logout",
    name: "Logout",
    component: {
      beforeRouteEnter(to, from, next) {
        Vue.prototype.$helpers.logout();
        location.replace(
          process.env.VUE_APP_URL +
            `/auth/login?redirect=${
              to.fullPath != "/auth/logout" ? to.fullPath : ""
            }`
        );
      },
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("../views/partials/NotFound.vue"),
    meta: {
      header: false,
      editable: false,
      requiresAuth: false,
      title: "صفحه‌ی مورد نظر یافت نشد",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base:
    process.env.VUE_APP_ENV == "production" &&
    process.env.VUE_APP_SUB_FOLDER_DEPLOY_STATUS == "true"
      ? "/" + process.env.VUE_APP_SUB_FOLDER_DEPLOY_DIR + "/"
      : process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // تغییر عنوان صفحه
  document.title = to.meta.title ? to.meta.title : "پنل کاربری";

  let userAuth = Vue.prototype.$helpers.userAuth();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!userAuth) {
      next({
        path: "/auth/login",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to, from) => {

  if(to.meta.isFullView == true){
    document.getElementsByTagName("html")[0].classList.add('isFullView');
  }else{
    document.getElementsByTagName("html")[0].classList.remove('isFullView')
  }

  document.body.setAttribute("data-route", to.name);
  store.commit("routeChanged", to);
});

export default router;
