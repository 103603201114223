<template>
<div>
    <div class="modal fade bootstrap-fix widgets"  id="global-dashboard-modal3" tabindex="-1" role="dialog" style="display: none" :data-component="modalClass" v-bind="globalModal.attributes">
        <div class="modal-dialog-before"></div>
        <div class="modal-dialog-box modal-dialog-centered" role="document">
            <div class="modal-content widget-modal-dashboard" style="max-width:100%" :class="{ 'small-modal': globalModal.smallModal }">
                <div class="modal-body global-modal-body p-0">
                    <component v-bind:is="_component" :data="globalModal.data"></component>
                </div>
                <!-- <button type="button" class="tgju-btn tgju-btn-light close-modal3" @click="closeModal" data-dismiss="modal" aria-label="Close" :hidden="true">
                    <span aria-hidden="true">بستن</span>
                </button>
                <button type="button" class="tgju-btn tgju-btn-danger diagram-btn-modal3" @click="openDiagram" data-dismiss="modal" aria-label="Close" :hidden="true">
                    <span aria-hidden="true">انتخاب شاخص</span>
                </button>
                <button type="button" class="tgju-btn tgju-btn-dark indicators-btn-modal4" @click="openIndicator" data-dismiss="modal" aria-label="Close" :hidden="true">
                    <span aria-hidden="true">اندیکاتورها</span>
                </button> -->
            </div>
        </div>
    </div>
    <transition name="slide-fade">
        <div v-if="show" class="indicators-box">
            <div class="indicators-box-close" @click="show = !show"><i class="uil uil-multiply pointer"></i></div>
            <div id="indicators-box-inner">
                <iframe class="indicator-iframe" v-if="iframeloaded" :src="iframeSrc"></iframe>
            </div>
        </div>
    </transition>
    <div v-if="show" class="indicators-box-bg"></div>

</div>
</template>

<script>
// این ویو برای مودال ایجاد تحلیل جدید استفاده میشود (تریدینگ ویو)
export default {
    name: 'Modal3',
    components: {},
    data: function () {
        return {
            globalModal: {
                title: '',
                icon: '',
                smallModal: false,
                attributes: {},
                data: {},
            },
            component: null,
            isMobile: false,
            modalClass: '',
            callbackEvent: null,
            show: false,
            iframeloaded: false,
            iframeSrc: 'https://www.tgju.org/news/category-embed/93978/%D8%A7%D9%86%D8%AF%DB%8C%DA%A9%D8%A7%D8%AA%D9%88%D8%B1%D9%87%D8%A7-2?01',
        }
    },
    computed: {
        _component() {
            if (!this.component) return null;

            return () => import(`@/components/Modals/${this.component}`);
        }
    },
    mounted() {
        window.addEventListener('message', this.receiveMessage)
        this.$root.$on('open_modal3', (data) => {
            let modal = 'open_' + data.modal;
            this.component = data.component;
            if(data.isMobile != undefined){
                this.isMobile = data.isMobile;
            }else{
                if (Vue.prototype.$helpers.checkDevice('app')) {
                    this.isMobile= true;
                }
            }            this.modalClass = this.component.split('/').reverse()[0].replace('.vue', '');
            this.callbackEvent = data.callbackEvent;

            this[modal](data.config);
        });
    },
    methods: {
        receiveMessage (event) {
            if (event.data == "close") {
                this.closeModal();
            } else if (event.data == "diagram") {
                this.openDiagram();
            } else if (event.data == "indicator") {
                this.openIndicator();
            }
        },
        // این متد برای تنظیم ویژگی های مدال و در نهایت نمایش مودال با توجه به ویژگی های درخواستی میباشد
        open_general_modal(config) {
            this.globalModal.title = config.title;
            this.globalModal.icon = config.icon;

            if (typeof config.smallModal != 'undefined') {
                this.globalModal.smallModal = config.smallModal;
            }

            if (typeof config.attributes != 'undefined') {
                this.globalModal.attributes = config.attributes;
            }

            if (typeof config.data != 'undefined') {
                this.globalModal.data = config.data;
            }

            $("#global-dashboard-modal3").show();
        },
        // این متد برای بستن مواد استفاده میشود
        closeModal() {
            this.$root.$emit(this.callbackEvent, {
                status: false,
                data: null,
            });
            $("#global-dashboard-modal3").hide();
            $('body').removeClass('show-modal3');
            this.component = null;
            // $(this).parents('.modal').eq(0).toggle(); return false;
        },
        // این متد برای بستن مودال و ارسال یک سری داده به پرنت استفاده میشود
        saveSettings(settings) {
            this.$root.$emit(this.callbackEvent, settings);
            $("#global-dashboard-modal3").hide();
        },
        // این تابع برای ارسال داده های مد نظر به پرنت استفاده میشود
        emitData(action, data, hideModal = false) {
            this.$root.$emit(this.callbackEvent, { action, data });

            if (hideModal) {
                $("#global-dashboard-modal3").hide();
            }
        },
        // این متد برای باز کردن مودال انتخاب شاخص استفاده میشود
        openDiagram() {
            let callbackEvent = 'jQuery.hColumns' + new Date().getTime();
            localStorage.setItem('jQuery_hColumns_event', callbackEvent);

            this.$root.$on(callbackEvent, (data) => {
                this.$root.$off(callbackEvent);
                $('.tv-iframe-wrapper').html(`<iframe id="tv-iframe" src="${this.$helpers.tv4_url()}/?symbol=${data.symbol}&item_id=${data.item_id}&full=1" style="border:none;width: 100%;display: inline-block;height: 750px;"></iframe>`);
            });

            $('#indicator-modal').show();
            $('#indicator-modal #columns2').html('');
            this.$helpers.diagrams_render();
        },
        // این متد برای باز کردن مودال  اخبار اندیکاتورها استفاده میشود
        openIndicator() {
            this.iframeloaded = true;
            this.show = !this.show;
        },
    },
}
</script>
