<template>
<div>
    <!-- مودال گلوبال -->
    <div :class="'modal fade bootstrap-fix widgets ' + (isMobile ? 'mobile-app-modal' : '')"  id="global-dashboard-modal2" tabindex="-1" role="dialog" style="display: none;" :data-component="modalClass" v-bind="globalModal.attributes">
        <div v-if="isMobile" class="modal-dialog-before" onclick="$(this).parents('.modal').eq(0).toggle(); return false;" data-dismiss="modal"></div>
        <div v-else class="modal-dialog-before"></div>        
        <div class="modal-dialog-box modal-dialog-centered" role="document">
            <div class="modal-content widget-modal-dashboard" :class="{ 'small-modal': globalModal.smallModal }">
                <div class="modal-header">
                    <h5 class="modal-title" id="global-Modal-Title"><i :class="globalModal.icon"></i>{{ globalModal.title }}</h5>
                    <button type="button" class="close" onclick="$(this).parents('.modal').eq(0).toggle(); return false;" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body global-modal-body p-0">
                    <component v-bind:is="_component" :data="globalModal.data"></component>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// این ویو هم برای برخی مودال های عمومی سایت استفاده میشود
export default {
    name: 'Modal2',
    components: {},
    data: function () {
        return {
            globalModal: {
                title: '',
                icon: '',
                smallModal: false,
                attributes: {},
                data: {},
            },
            component: null,
            isMobile: false,
            modalClass: '',
            callbackEvent: null,
        }
    },
    computed: {
        _component() {
            if (!this.component) return null;

            return () => import(`@/components/Modals/${this.component}`);
        }
    },
    mounted() {
        this.$root.$on('open_modal2', (data) => {
            let modal = 'open_' + data.modal;
            this.component = data.component;
            if(data.isMobile != undefined){
                this.isMobile = data.isMobile;
            }else{
                if (Vue.prototype.$helpers.checkDevice('app')) {
                    this.isMobile= true;
                }
            }            this.modalClass = this.component.split('/').reverse()[0].replace('.vue', '');
            this.callbackEvent = data.callbackEvent;

            this[modal](data.config);
        });
    },
    methods: {
        // این متد برای تنظیم ویژگی های مودال عمومی و در نهایت نمایش مودال با توجه به ویژگی های درخواستی میباشد
        open_general_modal(config) {
            this.globalModal.title = config.title;
            this.globalModal.icon = config.icon;

            if (typeof config.smallModal != 'undefined') {
                this.globalModal.smallModal = config.smallModal;
            }

            if (typeof config.attributes != 'undefined') {
                this.globalModal.attributes = config.attributes;
            }

            if (typeof config.data != 'undefined') {
                this.globalModal.data = config.data;
            }

            $("#global-dashboard-modal2").show();
        },
        // این متد برای بستن مواد استفاده میشود
        closeModal() {
            $("#global-dashboard-modal2").hide();
        },
        // این متد برای بستن مودال و ارسال یک سری داده به پرنت استفاده میشود
        saveSettings(settings) {
            this.$root.$emit(this.callbackEvent, settings);
            $("#global-dashboard-modal2").hide();
        },
        // این تابع برای ارسال داده های مد نظر به پرنت استفاده میشود
        emitData(action, data, hideModal = false) {
            this.$root.$emit(this.callbackEvent, { action, data });

            if (hideModal) {
                $("#global-dashboard-modal2").hide();
            }
        }
    },
}
</script>
