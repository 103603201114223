import { json } from 'body-parser';
import store from './../store/index';

export default {
    url: function(path = '') {
        const base_url = window.location.origin;

        let url = (path) ? base_url + '/' + path : base_url;

        return url;
    },

    removeComma: function(str) {
        str = String(str);
        return str.replace(/,/g, '');
    },

    en2fa: function(str) {
        str = String(str);

        str = str.replace(/0/g, '۰');
        str = str.replace(/1/g, '۱');
        str = str.replace(/2/g, '۲');
        str = str.replace(/3/g, '۳');
        str = str.replace(/4/g, '۴');
        str = str.replace(/5/g, '۵');
        str = str.replace(/6/g, '۶');
        str = str.replace(/7/g, '۷');
        str = str.replace(/8/g, '۸');
        str = str.replace(/9/g, '۹');

        return str;
    },

    // number_format(number, opts = {}) {
    // 	opts.fa = (opts.fa) ? opts.fa : false;
    // 	number = new Intl.NumberFormat('en-US', {
    // 		style: 'decimal'
    // 	}).format(number);

    // 	if (opts.fa) {
    // 		number = this.en2fa(number);
    // 	}

    // 	return number;
    // },

    json_pars(json) {
        return JSON.parse(json);
    },

    api_url(path) {
        return process.env.VUE_APP_API_URL;
    },
    tv4_url(path) {
        return process.env.VUE_APP_TRADINGVIEW_URL;
    },
    client_url(path) {
        return process.env.VUE_APP_URL;
    },
    
    getApiUrl(path) {
        let base_url = this.api_url() + '/v1';

        let url;
        if (path.startsWith("http") || path.startsWith("https")) {
            url = path;
        } else {
            url = base_url + path;
        }

        return url;
    },

    makeRequest: function(method, path, data = []) {
        method = method.toUpperCase();

        let url = this.getApiUrl(path);

        let is_api;
        if (path.startsWith("http") || path.startsWith("https")) {
            is_api = false;
        } else {
            is_api = true;
        }

        if ((method == 'GET' || method == 'DELETE') && Object.keys(data).length) {
            let queryString = Object.keys(data).map(key => {
                let data_value = data[key];
                if (data_value === null) data_value = '';
                if (data_value === false) data_value = 0;
                if (data_value === true) data_value = 1;

                return key + '=' + data_value
            }).join('&');

            if (url.indexOf('?') > 0) url = url + '&' + queryString;
            else url = url + '?' + queryString;

            data = [];
        } else {
            let formData = new FormData();

            for (let data_key in data) {
                let data_value = data[data_key];
                if (data_value === null) data_value = '';
                if (data_value === false) data_value = 0;
                if (data_value === true) data_value = 1;

                formData.append(data_key, data_value);
            }

            data = formData;
        }

        // if(url.indexOf('no-revision') > 0){
        //     url = url;
        // }else if(url.indexOf('?') > 0){
        //     url = url + '&revision=' + new Date().getTime();
        // }

        switch (url) {
            case url.indexOf('no-revision') > 0:
              break;
            case url.indexOf('?') > 0:
                url = url + '&revision=' + new Date().getTime();
              break;
          }

        // if (url.indexOf('?') > 0) url = url + '&revision=' + new Date().getTime();
        // else url = url + '?' + 'revision=' + new Date().getTime();

        let headers;
        if (is_api) {
            headers = {
                'Accept': 'application/json',
                'Authorization': 'Basic ' + store.state.accessToken,
                'content-type': 'application/x-www-form-urlencoded',
            };
        }

        return new Promise((resolve, reject) => {
            axios({
                method,
                url,
                headers,
                data
            }).then(res => {
                let response = {
                    status: res.status,
                    data: res.data,
                };

                resolve(response);
            }).catch(error => {
                let response;

                if (error.response) {
                    // The request was made and the server responded with a status code
                    response = {
                        status: error.response.status,
                        data: error.response.data,
                    };
                } else if (error.request) {
                    // The request was made but no response was received
                    response = {
                        status: null,
                        data: null,
                    };
                } else {
                    // Something happened in setting up the request that triggered an Error
                    response = {
                        status: null,
                        data: null,
                    };

                    // console.log('Error', error.message);
                }

                if (is_api && response.status == 401) {
                    this.logout();
                    var sub_folder_deploy = (process.env.VUE_APP_ENV == 'production' && process.env.VUE_APP_SUB_FOLDER_DEPLOY_STATUS == "true") ? (process.env.VUE_APP_SUB_FOLDER_DEPLOY_DIR + '/') : '';
                    window.location.href = this.url(sub_folder_deploy + 'auth/login');
                }

                resolve(response);
            });
        });
    },

    api_alert: function(api_response) {
        return new Promise((resolve, reject) => {
            if (api_response.status == 200) {
                resolve({
                    error: false,
                    data: null,
                });
            } else if (api_response.status == 500) {
                Vue.prototype.$toast.error({
                    title: 'خطا',
                    message: 'مشکلی در سمت سرور رخ داده است'
                });

                resolve({
                    error: true,
                    data: api_response.data.response,
                });
            } else {
                let error_message = '';
                if (typeof api_response.data.response.error === 'string') {
                    error_message = api_response.data.response.message;
                } else if (typeof api_response.data.response.error === 'object') {
                    error_message = '<ul>';
                    for (let index in api_response.data.response.error) {
                        let this_error = api_response.data.response.error[index];
                        error_message += '<li>' + this_error + '</li>';
                    }
                    error_message += '</ul>';
                }

                Vue.prototype.$toast.error({
                    title: 'خطا',
                    message: error_message ? error_message : 'در انجام عملیات مد نظر مشکلی رخ داده است'
                });

                resolve({
                    error: true,
                    data: api_response.data.response,
                });
            }
        });
    },

    userInfo() {
        let userInfo = (store.state.user) ? store.state.user : {};
        
        if (userInfo.length > 0)
            return userInfo;
        else
            return this.isJson(localStorage.getItem('user_data')) ? this.json_pars(localStorage.getItem('user_data')) : {};
    },
    
    userAuth() {
        let userAuth = (store.state.user) ? true : false;
        
        if (userAuth)
            return userAuth;
        else
            return this.isJson(localStorage.getItem('user_data')) ? true : false;
    },

    user() {
        let user = store.state.user;

        user = {
            id: 2,
            firstname: 'کاربر',
            lastname: 'تست',
        };

        return user;
    },

    logout() {

        localStorage.removeItem('access_token');
        localStorage.removeItem('InternalNotificationToken');
        localStorage.removeItem('InternalNotification');
        localStorage.removeItem('system_data');
        localStorage.removeItem('user_data');
        localStorage.removeItem('dashboards');

        window.indexedDB.deleteDatabase("firebase-installations-database");
        window.indexedDB.deleteDatabase("firebase-messaging-database");

        store.replaceState({
            layout: store.state.layout,
            sidebar: store.state.sidebar,
            user: null,
            accessToken: null,
            systemData: null,
            currentRoute: null
        });

    },

    setCookie(name, value, days) {
        var expires = "";

        if (typeof days != 'undefined' && days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }

        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },

    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');

        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }

        return null;
    },

    eraseCookie(name) {
        document.cookie = name + '=; Max-Age=-99999999;';
    },

    dev_mode() {
        let dev_mode = (localStorage.getItem('dev')) ? true : false;

        return dev_mode;
    },

    dev_id_mode() {
        let dev_mode = false;

        if ([2, 4, 11, 15, 24, 46].includes(Number(store.state.user.id))) {
            dev_mode = true;
        }

        return dev_mode;
    },

    setData() {
        store.commit('setAccessToken', localStorage.getItem('access_token'));

        let dev_mode = this.dev_mode();

        let promises = [];

        let new_promise = null;

        if(!window.location.href.includes('comment?subject')){
            // setLayout
            new_promise = new Promise((resolve, reject) => {
                store.dispatch('setLayout', {
                    forced: dev_mode ? false : true
                }).then(() => {
                    resolve();
                });
            });

            promises.push(new_promise);

            // setSidebar
            new_promise = new Promise((resolve, reject) => {
                store.dispatch('setSidebar', {
                    forced: dev_mode ? false : true
                }).then(() => {
                    resolve();
                });
            });

            promises.push(new_promise);
        }

        var sub_folder_deploy = (process.env.VUE_APP_ENV == 'production' && process.env.VUE_APP_SUB_FOLDER_DEPLOY_STATUS == "true") ? ("/" + process.env.VUE_APP_SUB_FOLDER_DEPLOY_DIR) : '';

        // بازسازی روت پروفایل کاربران                
        let user_profile = undefined;
        let uri = window.location.pathname.split(sub_folder_deploy + '/profile/_/')
        if (typeof uri == 'object') {
            user_profile = sub_folder_deploy +'/profile/_/' + uri[1];
        }

        
        //-------------
        if (![  sub_folder_deploy + '/auth/login', 
                sub_folder_deploy + '/technical/view',
                sub_folder_deploy + '/auth/logout',
                sub_folder_deploy + '/auth/sso',
                sub_folder_deploy + '/comment',
                sub_folder_deploy + '/tgju-forum-technicals',
                sub_folder_deploy + '/technicals',
                sub_folder_deploy + '/analysts',
                user_profile
            ].includes(window.location.pathname)) {
            // ایف دوم برای این گزاشته شد که نمایش یک تکنیکال ، نیاز به لاگین نباشد
            if (!window.location.pathname.includes('/technical/view')) {
                // setDashboards
                new_promise = new Promise((resolve, reject) => {
                    store.dispatch('setDashboards', { forced: dev_mode ? false : true }).then(() => {
                        resolve();
                    });
                });

                promises.push(new_promise);

                //setUserData
                new_promise = new Promise((resolve, reject) => {
                    store.dispatch('setUserData', { forced: dev_mode ? false : true }).then(() => {
                        resolve();
                    });
                });

                promises.push(new_promise);
            }
        }

        return Promise.all(promises);
    },

    firebase_conflict_solver(Layout) {
        var parser = new DOMParser();
        var _document = parser.parseFromString(Layout, "text/html");
        _document.querySelectorAll('[data-dashboard-remove="true"]').forEach(e => e.remove());
        _document.querySelectorAll('#tgju-notification').forEach(e => e.remove());
        return _document.documentElement.innerHTML;
    },

    prepare_layout(layout) {
        return new Promise((resolve, reject) => {
            $("head script[data-notification='najva']").remove();
            $("head link[rel='manifest']").remove();
            $('meta[charset]').remove();
            $('meta[http-equiv="X-UA-Compatible"]').remove();
            $('meta[name="viewport"]').remove();
            $('[data-dashboard-remove="true"]').remove();
            $('noscript').remove();

            let scripts_data = this.find_scripts(layout);
            layout = scripts_data.layout;

            let head = layout.replace(/^.*?<head>(.*?)<\/head>.*?$/s, "$1");
            let body = layout.replace(/^.*?<body.*?>(.*?)<\/body>.*?$/s, "$1");

            head = head.replace('<link rel="shortcut icon" href="https://static.tgju.org/views/default/images/favicon.ico">', '');
            head = head.replace('<base href="https://www.tgju.org/">', '');
            head = head.replace(/<title>.*<\/title>/, '');
            head = head.replace(/dashboard-tmp/g, 'dashboard');

            $('head').prepend(head);
            $('body').prepend(body);

            // $('.nav-links').prepend('<div class="dashboard-menu-btn"><i class="uil uil-list-ui-alt"></i></div>');


            setTimeout(() => {
                this.add_scripts(scripts_data.scripts);
                setTimeout(() => {
                    this.add_inline_scripts();
                }, 500);
                this.replace_rel_to_abs('a');

                resolve(layout);
            }, 100);
        });
    },

    replace_rel_to_abs(selector) {
        let all_links = document.querySelectorAll(selector);
        const TGJU_LINK = 'https://www.tgju.org';

        let href;
        all_links.forEach(element => {
            href = element.getAttribute('href');
            element.setAttribute('target', '_blank');

            if (!href || href == '#') return;
            if (href.indexOf(':') > -1) return;
            if (href == '.') href = TGJU_LINK;
            if (href.startsWith("http") || href.startsWith("https")) return;
            href = href.replace(/^\/|\/$/g, '');

            href = TGJU_LINK + '/' + href;

            element.setAttribute('href', href);
        });
    },

    find_scripts(layout) {
        let regexp = /<script .*?src="(.*?)".*?<\/script>/g;
        let array = [...layout.matchAll(regexp)];
        let scripts = [];
        array.forEach(script => {
            layout = layout.replace(script[0], '');
            if (script[1].indexOf('rocket-loader') > -1) return;
            if (script[1].indexOf('jquery.js') > -1) return;
            // if ( script[1].indexOf('highcharts-concat') > -1 ) return;
            // if ( script[1].indexOf('highcharts-indicators-concat') > -1 ) return;
            if (!script[1].startsWith("http") && !script[1].startsWith("https")) return;
            scripts.push(script[1]);
        });

        return {
            layout,
            scripts,
        };
    },

    add_scripts(scripts) {
        let init_src;
        scripts.forEach(src => {
            if (src.indexOf('init.js') > -1) {
                init_src = src;
                return;
            }

            var script = document.createElement("script");
            script.src = src;
            document.body.appendChild(script);
        });

        var script = document.createElement("script");
        script.src = init_src;
        document.body.appendChild(script);

        window.__cfRLUnblockHandlers = true;
    },

    add_inline_scripts() {
        let _scripts = document.querySelectorAll('script');
        _scripts.forEach(script => {
            if (script.hasAttribute('src')) return;
            if (script.getAttribute('type') == 'application/ld+json') return;

            var script_content = script.textContent;
            script.remove();

            var new_script = document.createElement("script");
            var inlineScript = document.createTextNode(script_content);
            new_script.appendChild(inlineScript);
            document.body.appendChild(new_script);
        });
    },

    native_notification_show(data) {
        window.notification = new Notification(data.title, {
            body: data.body,
            icon: data.icon,
            silent: (typeof data.custom_sound != 'undefined' && data.custom_sound) ? true : false,
        });

        if (typeof data.custom_sound != 'undefined' && data.custom_sound) {
            let audio = new Audio('/sounds/notification.mp3');
            audio.play();
        }
    },

    native_notification(data) {
        if (!("Notification" in window)) {
            Vue.prototype.$toast.warn({
                title: 'اخطار',
                message: 'مرورگر شما نوتیفیکیشن را پشتیبانی نمیکند لطفا مرورگر خود رو آپدیت نمایید'
            });

            return;
        } else if (Notification.permission === "granted") {
            this.native_notification_show(data);
        } else if (Notification.permission !== 'denied') {
            Notification.requestPermission(function(permission) {
                if (permission === "granted") {
                    this.native_notification_show(data);
                }
            });
        }

        if (window.notification) {
            window.notification.onclick = function() {
                window.focus();

                if (data.callback_url) {
                    document.location.href = data.callback_url;
                }
            };
        }
    },

    confirm(title, options = {}) {
        return new Promise((resolve, reject) => {
            let callbackEvent = 'confirm-' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'ConfirmModal.vue',
                config: {
                    title,
                    smallModal: true,
                    data: options
                }
            };

            this.boomerangEvent('open_modal2', callbackEvent, modal_data).then((response) => {
                resolve(response.data.confirmed);
            });
        });
    },

    unbindObject(object) {
        return JSON.parse(JSON.stringify(object));
    },

    getIndex(object, id) {
        let index;

        object.forEach((item, _index) => {
            if (item.id == id) {
                index = _index;
            }
        });

        return index;
    },

    getWidgets(api_data) {
        return new Promise((resolve, reject) => {
            this.makeRequest('POST', '/dashboard/widgets', api_data).then(api_response => {
                this.api_alert(api_response);

                if (api_response.status == 200) {
                    let _widgets = api_response.data.response;
                    let widgets_count = _widgets.length;

                    let widgets = [];
                    if (!widgets_count) resolve(widgets);

                    _widgets.forEach((item, i) => {
                        item.component = this.widgetsComponents(item.widget.type);
                        item.resizerShow = false;
                        item.sizeClass = this.widgetSize(item.widget.size);

                        widgets.push(item);

                        if (i + 1 == widgets_count) {
                            resolve(widgets);
                        }
                    });
                }
            });
        });
    },

    saveWidget(widget) {
        return new Promise((resolve, reject) => {
            if (widget.read_only) {
                reject();
                return;
            }

            let api_data = {
                title: widget.title,
                size: widget.size,
                type: widget.type,
                data: JSON.stringify(widget.data),
            };

            this.makeRequest('POST', '/dashboard/widget-save/' + widget.id, api_data).then(api_response => {
                this.api_alert(api_response);

                if (api_response.status == 200) {
                    resolve();
                }
            });
        });
    },

    widgetsComponents(widget = false) {
        const map = {
            'notification': 'Notification',
            'price': 'Price',
            'favorite': 'Favorite',
            'note': 'Note',
            'watchlist': 'WatchList',
            'trading-view': 'TradingView',
            'todo': 'Todo',
            'chart': 'Chart',
            'friend-posts': 'FriendPosts',
            'my-posts': 'MyPosts',
            'news': 'News',
            'calendar': 'CalendarView',
        };

        if (widget === false) return map;

        return (typeof map[widget] != 'undefined') ? map[widget] : null;
    },

    widgetSize(size = false) {
        const map = {
            'small': 'col-xl-4',
            'medium': 'col-xl-6',
            'large': 'col-xl-8',
            'full': 'col-xl-12',
        };

        if (size === false) return map;

        return (typeof map[size] != 'undefined') ? map[size] : null;
    },

    downloadFile(path) {
        let url = this.getApiUrl(path);

        if ($('#download-iframe').length) {
            $('#download-iframe').remove();
        }

        $('body').append(`<iframe id="download-iframe" src="${url}" style="display: none;"></iframe>`);
    },

    boomerangEvent(event, callbackEvent, eventData) {
        return new Promise((resolve, reject) => {
            Vue.prototype.$vm.$root.$emit(event, eventData);

            Vue.prototype.$vm.$root.$on(callbackEvent, (response) => {
                resolve(response);
            });
        });
    },

    number_format_decimal(num, decimal) {
        if (typeof decimal == 'undefined') {
            decimal = 0;
        }

        num = String(num);
        var number_parts = num.split('.');
        var formatted_num = this.number_format(number_parts[0]);

        if (number_parts[1] && decimal) {
            formatted_num += '.' + number_parts[1].substring(0, decimal);
        }

        return formatted_num;
    },

    number_format(number) {
        number = number.toString();

        var number_arr = number.split('.');
        number = this.number_format_part2(number_arr[0]);
        if (number_arr[1]) number += '.' + number_arr[1].substr(0, 2);

        return number;
    },

    number_format_part2(number, decimals, decPoint, thousandsSep) {
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
        var n = !isFinite(+number) ? 0 : +number
        var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
        var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
        var dec = (typeof decPoint === 'undefined') ? '.' : decPoint
        var s = ''

        var toFixedFix = function(n, prec) {
            var k = Math.pow(10, prec)
            return '' + (Math.round(n * k) / k)
                .toFixed(prec)
        }

        // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || ''
            s[1] += new Array(prec - s[1].length + 1).join('0')
        }

        return s.join(dec)
    },

    diagrams_render(type = 'dashboard') {
        var link = document.createElement('link');
        link.setAttribute('rel', 'stylesheet');
        link.setAttribute('href', 'https://static.tgju.org/components/hcolumns/css/hcolumns.css?v=14');
        document.getElementsByTagName('head')[0].appendChild(link);
        $('body').addClass('mobile-modal-show'); 

        $.getScript(this.client_url() + "/jquery.hcolumns.js", function(data, textStatus, jqxhr) {
            $("#columns2").hColumns({
                noContentString: $('html').attr('lang') == 'fa' ? 'شاخصی در این بخش وجود ندارد' : 'There is no index in this section',
                searchable: !0,
                searchPlaceholderString: $('html').attr('lang') == 'fa' ? 'فیلتر' : 'Filter',
                customNodeTypeIndicator: {},
                customNodeTypeHandler: {},
                nodeSource: function(node_id, callback) {
                    if (!$('.column-loading').is(':visible')) {
                        $('.column-loading').show();
                        var category_id = node_id == null ? 0 : node_id;
                        var isMarket = $("#columns2.column-view-container .column > ul > li[data-node-id='" + node_id + "']").data('market');
                        if (typeof isMarket == "undefined")
                            isMarket = !1;
                        $.ajax({
                            type: 'get',
                            url: "https://api.tgju.org/v1/market/finder/list",
                            data: {
                                type: (type) ? type : '',
                                market: isMarket,
                                id: category_id,
                                lang: $('html').attr('lang')
                            },
                            headers: {
                                'X-Client-Name': 'main_site',
                                'X-Client-Version': '1',
                                'X-Client-SubSystem': 'main_site',
                            }
                        }).done(function(data) {
                            data = data.response.items;
                            $('.column-loading').hide();
                            $('body').removeClass('mobile-modal-show');
                            return callback(null, data)
                        }).fail(function() {
                            return callback("AJAX error")
                        })
                    }
                }
            });
            $('.column-view-composition').append('<span class="column-loading">درحال بارگزاری</span>');
            $('#indicator-modal .modal-close').click(function(){
                $('body').removeClass('mobile-modal-show');
            });
        });
    },

    diagrams_render_with_full_selection(type = 'dashboard') {
        var link = document.createElement('link');
        link.setAttribute('rel', 'stylesheet');
        link.setAttribute('href', 'https://static.tgju.org/components/hcolumns/css/hcolumns.css?v=14');
        document.getElementsByTagName('head')[0].appendChild(link);
        $('body').addClass('mobile-modal-show'); 

        $.getScript(this.client_url() + "/jquery.hcolumns.js", function(data, textStatus, jqxhr) {
            $("#columns2").hColumns({
                noContentString: $('html').attr('lang') == 'fa' ? 'شاخصی در این بخش وجود ندارد' : 'There is no index in this section',
                searchable: !0,
                searchPlaceholderString: $('html').attr('lang') == 'fa' ? 'فیلتر' : 'Filter',
                customNodeTypeIndicator: {},
                customNodeTypeHandler: {},
                nodeSource: function(node_id, callback) {
                    if (!$('.column-loading').is(':visible')) {
                        $('.column-loading').show();
                        var category_id = node_id == null ? 0 : node_id;
                        var isMarket = $("#columns2.column-view-container .column > ul > li[data-node-id='" + node_id + "']").data('market');
                        if (typeof isMarket == "undefined")
                            isMarket = !1;
                        $.ajax({
                            type: 'get',
                            url: "https://api.tgju.org/v1/market/finder/list",
                            data: {
                                type: (type) ? type : '',
                                market: isMarket,
                                id: category_id,
                                lang: $('html').attr('lang')
                            },
                            headers: {
                                'X-Client-Name': 'main_site',
                                'X-Client-Version': '1',
                                'X-Client-SubSystem': 'main_site',
                            }
                        }).done(function(data) {
                            data = data.response.items;
                            let i = 0;
                            for (let item of data) {
                                if (item.type !== 'folder' && i < 3) {
                                    i++;
                                }
                                if (i >= 3) {
                                    var market_id = [];
                                    for (let [index, val] of data.entries()) {
                                        market_id.push(val.item_id);
                                        if (index >= 10) {
                                            break;
                                        }
                                    }
                                    let fullSelection = {
                                        id: 'stocks-fund-full-selection',
                                        label: "همه نمادها",
                                        symbol: "",
                                        item_id: market_id,
                                        type: "link",
                                        url: "",
                                    }
                                    data = [fullSelection].concat(data);
                                    break;
                                }
                            }
                            $('.column-loading').hide();
                            $('body').removeClass('mobile-modal-show');
                            return callback(null, data)
                        }).fail(function() {
                            return callback("AJAX error")
                        })
                    }
                }
            });
            $('.column-view-composition').append('<span class="column-loading">درحال بارگزاری</span>');
            $('#indicator-modal .modal-close').click(function(){
                $('body').removeClass('mobile-modal-show');
            });
            
        });
    },

    setEvent(callback) {
        return new Promise((resolve, reject) => {
            store.dispatch('setEvent', callback).then((status) => {
                resolve(status);
            });
        });
    },

    socialShare(type, title, url) {
        // url = encodeURI(url);
        let finalUrl;

        switch (type) {
            case 'facebook':
                finalUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&t=${title}`;
                break;

            case 'twitter':
                finalUrl = `https://twitter.com/intent/tweet?source=${url}&text=${title}`;
                break;

            case 'telegram':
                finalUrl = `https://telegram.me/share/url?url=${url}&text=${title}`;
                break;
        }

        window.open(finalUrl);
    },

    getParent(component) {
        let parent;
        if (component.$parent.$options.name != component.$options.name) {
            return component.$parent;
        }

        return this.getParent(component.$parent);
    },

    isJson(jsonString) {

        if (jsonString == null)
            return false

        try {
            JSON.parse(jsonString);
            return true;
        } catch (error) {
            return false;
        }
    },

    userSync() {
        return this.isJson(localStorage.getItem('user_data')) ? this.json_pars(localStorage.getItem('user_data')) : false;
    },

    runHelp(name){
        if(window.innerWidth > 992){
            if(this.getCookie(name) != null){
                return false;
            }else{
                if($('.tgju-help-btn').length) {
                    return true; 
                }
            }
        }
    },

    navigatorClient(test) {
        var userAgent = navigator.userAgent;
        var dataClient = 'web';
        var dataClientVersion = '';
        var dataClientAgent = '';
        if (userAgent.split(' ').length && userAgent.indexOf('tgju') !== -1) {
            var us = userAgent.split(' ').reverse()[0].split('/');
            if (us.length == 2) {
                dataClientVersion = us[1];
            }
            dataClientAgent = us.join('/');
        }
        dataClient = 'web';
        if (typeof(navigator) !== 'undefined' && 'standalone' in navigator && navigator.standalone && (/iphone|ipod|ipad/gi).test(navigator.platform) && (/Safari/i).test(navigator.appVersion)) {
            dataClient = 'ios-standalone';
        } else if (userAgent.indexOf('tgju-ios') !== -1) {
            dataClient = 'ios';
        } else if (userAgent.indexOf('tgju-android') !== -1 || userAgent.indexOf('TGJUAndroid') !== -1) {
            dataClient = 'android';
        } else if (userAgent.indexOf('tgju-macos') !== -1) {
            dataClient = 'macos';
        } else if (userAgent.indexOf('com.vandaw.tgju') !== -1 && userAgent.indexOf('Windows') !== -1 && userAgent.indexOf('Electron') !== -1) {
            dataClient = 'windows';
        } else if (userAgent.indexOf('com.vandaw.tgju') !== -1 && userAgent.indexOf('Linux') !== -1 && userAgent.indexOf('Electron') !== -1) {
            dataClient = 'linux';
        }
        
        return test ? test === dataClient : {dataClient: dataClient, dataClientVersion: dataClientVersion, dataClientAgent: dataClientAgent};
    },
    checkDevice(name){
        if(process.env.VUE_APP_ENV == 'local') {
            if(window.AppLayout !== 'web') {
                if(window.AppLayout == name) {
                    return true;
                }
                if(name == 'app') {
                    return true;
                }
                return false;
            }
        }
        if(name == "app"){
            if (
                this.navigatorClient().dataClient == "ios" ||
                this.navigatorClient().dataClient == "ios-standalone" ||
                this.navigatorClient().dataClient == "android" ||
                this.navigatorClient().dataClient == "macos" ||
                this.navigatorClient().dataClient == "windows" ||
                this.navigatorClient().dataClient == "linux"
            ) {
                return true;
            }else{
                return false;
            }
        }else if(name == "mobile"){
            if (
                this.navigatorClient().dataClient == "ios" ||
                this.navigatorClient().dataClient == "ios-standalone" ||
                this.navigatorClient().dataClient == "android"
            ) {
                return true;
            }else{
                return false;
            }
        }else if(name == "desktop"){
            if (
                this.navigatorClient().dataClient == "macos" ||
                this.navigatorClient().dataClient == "windows" ||
                this.navigatorClient().dataClient == "linux"
            ) {
                return true;
            }else{
                return false;
            }
        }else if(name == "web"){
            if (
                this.navigatorClient().dataClient == "web"
            ) {
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    }
}