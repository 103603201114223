import helpers from './helpers.js';
window.axios = require('axios');

//Import Froala Editor 
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/languages/fa';
//Import third party plugins
import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/js/third_party/font_awesome.min';
import 'froala-editor/js/third_party/spell_checker.min';
import 'froala-editor/js/third_party/image_tui.min';
// Import Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css';

import VueFroala from 'vue-froala-wysiwyg'

import VuePersianDatetimePicker from 'vue-persian-datetime-picker';

import CxltToastr from 'cxlt-vue2-toastr'
import 'cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css'

var VueScrollTo = require('vue-scrollto');

export default {
    install(Vue) {
        Vue.prototype.$helpers = helpers;

        Vue.use(VueFroala);
        Vue.config.productionTip = false;

        Vue.component('date-picker', VuePersianDatetimePicker);

        var toastrConfigs = {
            position: 'bottom left',
            closeButton: true,
            progressBar:true,
            useHtml: true,
            showMethod: 'fadeIn',
            hideMethod: 'fadeOut',
            timeOut: 5000
        }
        Vue.use(CxltToastr, toastrConfigs);

        Vue.use(VueScrollTo)
    }
}