const static_app_update_alert = {
    version : '3.0.0',
    items : [
        {
            name : 'تست ۱',
        },
        {
            name : 'تست ۲',
        },
        {
            name : 'تست ۳',
        },
        {
            name : 'تست ۴',
        },
        {
            name : 'تست ۶',
        }
    ]
};

export default static_app_update_alert;