<template>
<div>
    <div class="mobile-header">
        <div class="mobile-header-row">
            <div class="mobile-header-avatar" :style="showAvatar()">
                <a v-if="currentRoute == 'ProfileEdit'" href="javascript:void(0);" class="dashboard-avatar-change" style="border-radius: 50%;" @click="avatarFileDialog()">
                    <input type="file" id="avatar" class="d-none" @change="uploadAttachment($event.target.files,'avatar')">
                    <i class="fa fa-camera" aria-hidden="true"></i>
                </a>
                <slot v-if="currentRoute == 'ProfileEdit'">
                    <div v-if="avatarExist" class="delete-avatar"><i class="uil uil-times" @click.prevent="removeAvatar()"></i></div>
                </slot>
            </div>
            <div class="mobile-header-info-title-actions">
                <slot v-if="this.$route.params.username && $helpers.userAuth()">
                    <template v-if="isFollowing">
                        <a href="#" class="app-btn-outline-primary app-btn-danger" @click.prevent="follow()">
                            <i class="uil uil-minus-circle" aria-hidden="true"></i>
                            عدم دنبال‌کردن
                        </a>
                    </template>
                    <template v-else-if="!isMyProfile">
                        <a href="#" class="app-btn-outline-primary" @click.prevent="follow()">
                            <i class="uil uil-plus-circle" aria-hidden="true"></i>
                            دنبال‌کردن
                        </a>
                    </template>
                </slot>
                <slot v-else>
                    <router-link class="app-btn-outline-primary" :to="{ name: 'ProfileEdit' }"  v-if="currentRoute != 'ProfileEdit'">
                        ویرایش پروفایل
                    </router-link>
                </slot>
            </div>


        </div>
        
        <div class="mobile-header-info-title">
            <div class="mobile-p-title">
                <div class="d-flex" style="align-items: center;">
                    <h2> {{ !this.$route.params.username ? ( this.$helpers.userInfo().firstname +'  '+ this.$helpers.userInfo().lastname ) : (profileUser.firstname + ' ' + profileUser.lastname) }}</h2>
                    <div v-if="header_detail.tick_mark == null" class="tag user-tag">کاربر‌ عادی</div>
                    <div v-if="header_detail.tick_mark == 'analyst'" class="tag user-tag-primary">تحلیل‌گر</div>
                    <div v-if="header_detail.tick_mark == 'admin'" class="tag user-tag-warning">پشتیبان</div>
                </div>
                
                <span>
                    {{ !this.$route.params.username ? this.$helpers.userInfo().username : profileUser.username }}@
                </span>
            </div>
        </div>

        <div v-if="header_detail.analyst_rating" class="star-row" style="max-width: 85px;margin-right: 18px;margin-top: -10px;margin-bottom: 3px;">
            <i v-for="index in header_detail.analyst_rating" :key="index" class="fa fa-star" aria-hidden="true"></i>
            <i v-for="index in (5 - header_detail.analyst_rating)" :key="index" class="fa fa-star-o" aria-hidden="true"></i>
        </div>

        <div class="user-register-date">
            <i class="uil uil-calendar-alt"></i>
            تاریخ‌ عضویت: {{ !this.$route.params.username ? toRelative(this.$helpers.userInfo().created_at) :  toRelative(profileUser.created_at) }}
        </div>

        <div class="mobile-header-info-count">
            <div class="mobile-header-info-count-item">
                <h3>دنبال‌کنندگان</h3>   
                <span>{{ header_detail.followers }}</span>                         
            </div>
            <div class="mobile-header-info-count-item">
                <h3>دنبال‌شوندگان</h3>   
                <span>{{ header_detail.followings }}</span>                         
            </div>
            <div class="mobile-header-info-count-item">
                <h3>لایک‌ها</h3>  
                <span>{{ header_detail.likes }}</span>                          
            </div>
        </div>

        <div class="mobile-app-tabs" v-if="currentRoute != 'ProfileEdit'">

            <router-link :class="'mobile-app-tabs-items ' + ( currentRoute == 'Profile' || currentRoute == 'ProfileOthers' ? 'app-active-tab' : '')" :to="$route.params.username ? { name : 'ProfileOthers'} : { name: 'Profile' }" >
                <div class="mobile-app-tabs-item">
                    <div class="mobile-app-tabs-item-row">
                        <i class="uil-icon uil uil-comment-alt-lines"></i>
                        <h3>گفت‌وگوها</h3> 
                        <span class="badge badge-secondary">{{ header_detail.comments }}</span>
                    </div>
                </div>
            </router-link>

            <router-link :class="'mobile-app-tabs-items ' + ( currentRoute == 'ProfileTechnical' || currentRoute == 'ProfileTechnicalOthers' ? 'app-active-tab' : '')" :to="$route.params.username ? { name: 'ProfileTechnicalOthers' } : { name : 'ProfileTechnical'}" >
                <div class="mobile-app-tabs-item">
                    <div class="mobile-app-tabs-item-row">
                        <i class="uil-icon uil uil-comment-alt-lines"></i>
                        <h3>تحلیل‌ها</h3> 
                        <span class="badge badge-secondary">{{ header_detail.technicals }}</span>
                    </div>
                </div>
            </router-link>

        </div>
    </div>
</div>
</template>

<script>
import Vue from 'vue';
// این ویو لی اوت هدر در پروفایل می باشد
import moment from "moment-jalaali";
import "vue-swatches/dist/vue-swatches.css";

export default {
    name: 'App',
    components: {
        // 'dropdown-menu': DropdownMenu,
    },
    data: function () {
        return {
            tabs: 0,
            selectedCategory: 1,
            show_banner: true,
            isFollowing: true,
            avatarExist: false,
            coverExist: false,
            header_detail: {
                friend_counts: 0,
                twit_counts: 0
            },
            showShareMenu: false,
            profileUser: {
                id: undefined,
                firstname: '',
                lastname: '',
                username: undefined,
                avatar: {
                    name: undefined
                },
                opts: {
                    header_cover: undefined
                }
            },
            items: [
                { title: 'Click Me' },
                { title: 'Click Me' },
                { title: 'Click Me' },
                { title: 'Click Me 2' },
            ],
            currentRoute: this.$router.currentRoute.name,
        }
    },
    mounted() {
        this.$root.$on('increase-followings', () => {
            this.header_detail.followings++;
        });

        this.$root.$on('decrease-followings', () => {
            this.header_detail.followings--;
        });

        // در صورتی که پارامتر نام کاربری ست شده بود
        if (this.$route.params.username) {

            this.getUser(this.$route.params.username);
            if (this.$helpers.userAuth()) {
                this.isFriend(this.$route.params.username);
            }
        }
        // کال کردن این متد جهت دریافت اطلاعات هدر برای پروفایل
        this.headerDetail();
        if(this.$helpers.runHelp('ShowHelp')){
            this.open_help_modal();
        }
    },
    updated() {
        if (this.currentRoute == 'Profile' ||
            this.$store.state.user.username == this.$route.params.username ||
            this.currentRoute == 'ProfileFollowers' ||
            this.currentRoute == 'ProfileFollowing' ||
            this.currentRoute == 'ProfileTechnical') {
            this.$store.state.isMyProfile = true;
        } else {
            this.$store.state.isMyProfile = false;
        }
    },
    methods: {
        setActiveCategory() {
            var avtiveRoute = null;
            switch(this.selectedCategory){
                case 1:
                    avtiveRoute = this.$route.params.username ?  'ProfileOthers':  'Profile';
                    this.$router.push({name: avtiveRoute} );
                    break;
                case 2:
                    avtiveRoute = this.$route.params.username ?  'ProfileTechnicalOthers':  'ProfileTechnical';
                    this.$router.push({name: avtiveRoute} );
                    break;
                case 3:
                    avtiveRoute = this.$route.params.username ?  'ProfileFollowersOthers':  'ProfileFollowers';
                    this.$router.push({name: avtiveRoute} );
                    break;
                case 4:
                    avtiveRoute = this.$route.params.username ?  'ProfileFollowingOthers':  'ProfileFollowing';
                    this.$router.push({name: avtiveRoute} );
                    break;
            }
        },
        
        // این متد برای تغییر و نمایش مناسب زمان استفاده میشود
        toRelative(date) {
            return moment(date).fromNow();
        },
        // این متد جهت ارسال درخواست دریافت اطلاعات مناسب هدر استفاده میشود
        headerDetail() {
            let api_data = {
                username: this.$route.params.username ? this.$route.params.username : this.$helpers.userInfo().username
            };

            this.$helpers.makeRequest('GET', '/user/header-details/', api_data).then(
                api_response => {
                    this.$helpers.api_alert(api_response);

                    if (api_response.status == 200) {
                        this.header_detail = api_response.data;
                    }
                });
        },
        // این متد درخواست فالو و انفالو کاربر مورد نظر را هندل میکند .
        follow() {
            let api_data = {
                action: this.isFollowing ? 'unfollow' : 'follow'
            };

            this.$helpers.makeRequest('GET', '/user/follow/' + this.$route.params.username, api_data).then(
                api_response => {
                    this.$helpers.api_alert(api_response);

                    if (api_response.status == 200) {
                        let response = api_response.data.response;
                        this.isFollowing = !this.isFollowing;
                        if (this.isFollowing) {
                            if (!this.$store.state.isMyProfile) {
                                this.header_detail.followers++;
                            }
                        } else {
                            if (!this.$store.state.isMyProfile) {
                                this.header_detail.followers--;
                            }
                        }
                    }

                    if (api_response.status == 400) {
                        Vue.prototype.$toast.warn({
                            title: 'توجه',
                            message: 'شما پیش تر این کاربر را دنبال کرده اید'
                        });
                    }

                });
        },
        // این متد برای این استفاده میشود که اگر در پروفایل یک کاربر دیگر بودیم مشخص مینماید که آیا
        // این کاربر دنبال کرده ایم یا خیر
        isFriend(username) {
            let api_data = {
                username: username,
            };

            this.$helpers.makeRequest('GET', '/user/is-friend', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    this.isFollowing = api_response.data.response.status == 'success' ? true : false;
                }
            });
        },
        // این متد برای به اشتراک گذاشتن پروفایل کاربری استفاده میشود
        socialShare(type) {
            // داینامیک قابلیت اشتراک گذاری پروفایل دیگران و خود
            this.$helpers.socialShare(type, `پروفایل کاربری `, process.env.VUE_APP_URL + '/profile/' + (this.$route
                .params.username ? this.$route.params.username : this.$helpers.userInfo().username));
        },
        // این متد برای سینک نگهداشتن متغیر روت با رویت نیم فعلی
        syncCurrentRout() {
            this.currentRoute = this.$router.currentRoute.name;
        },
        // این متد جهت دریافت اطلاعات کاربر استفاده میشود
        getUser(username) {
            //آماده سازی دیتای درخواست ای پی آی
            let api_data = {
                username: username,
            };

            // ارسال درخواست ای پی آی
            this.$helpers.makeRequest('POST', '/user/get', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                // در صورتی در خواست ای پی آی پیام موفقیت داشت		 
                if (api_response.data.response.status === 'success') {
                    this.profileUser = api_response.data.response.detail;
                }

                // در صورتی که کاربر یافت نشد
                if (api_response.data.response.status === 'error') {

                    Vue.prototype.$toast.error({
                        title: 'خطا',
                        message: 'کاربر یافت نشد',
                    });

                    window.location.href = "/profile";
                }

            });
        },
        // این متد برای آپلود تصویر کاربر استفاده میشود
        uploadAttachment(fileList, type) {
            // آرایه ای از خطا های رایج
            let server_response = {
                file_extension_not_allowed: 'فرمت فایل انتخاب شده مجاز نیست',
                file_size_not_allowed: 'اندازه عکس مورد انتخاب شده مجاز نیست',
                file_upload_failed: 'مشکلی در آپلود بوجود آمد',
            };

            let attachment = [];
            if (fileList.length) {
                attachment = fileList[0];
            } else {
                attachment = null;
            }

            if (attachment) {
                let api_data = {
                    attachment,
                    type: type,
                };

                this.$helpers.makeRequest('POST', '/user/' + type + '-upload', api_data).then(api_response => {
                    this.$helpers.api_alert(api_response);

                    if (api_response.status == 400) {
                        Vue.prototype.$toast.error({
                            title: 'خطا',
                            message: server_response[api_response.data.response.message]
                        });

                        return 0;
                    }

                    if (api_response.status == 200) {
                        Vue.prototype.$toast.success({
                            title: 'موفق',
                            message: 'با موفقیت بارگزاری شد'
                        });

                        this.$store.dispatch('setUserData', {
                            forced: true
                        });
                        return 0;
                    }

                });
            }
        },
        // این متد فایل دیالوگ برای انتخاب عکس آواتار کاربر را هندل میکند
        avatarFileDialog() {
            document.getElementById('avatar').click()
        },
        // این متد فایل دیالوگ برای انتخاب عکس کاور کاربر را هندل میکند
        coverFileDialog() {
            document.getElementById('cover').click()
        },
        // این متد برای ساختن لینک نمایش مناسب آواتار کاربر در هدر استفاده میشود
        showCover() {
            let url = undefined;

            if (this.$route.params.username && this.$helpers.isJson(this.profileUser.opts))
                url = this.$helpers.api_url() + this.$helpers.json_pars(this.profileUser.opts)['header_cover']['name'];

            if (!this.$route.params.username && this.$helpers.userInfo().opts.header_cover)
                url = this.$helpers.api_url() + this.$helpers.userInfo().opts.header_cover.name;

            if (url == undefined) {
                this.coverExist = false;
            } else {
                this.coverExist = true;
            }

            // if (url.indexOf('undefined') > -1)
            // 	url = 'https://static.tgju.org/views/default/images/tgju/dash-header-bd.png';

            return `background-image: url("${ !url ? 'https://static.tgju.org/views/default/images/tgju/dash-header-bd.png' : url }");`;
        },
        // برای پاک کردن عکس کاور
        removeCover() {
            let api_data = {
                action: 'delete',
            };

            this.$helpers.makeRequest('POST', '/user/cover-upload', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 400) {
                    Vue.prototype.$toast.error({
                        title: 'خطا',
                        message: 'خطایی رخ داده است'
                    });
                    return 0;
                }

                if (api_response.status == 200) {
                    Vue.prototype.$toast.success({
                        title: 'موفق',
                        message: 'با موفقیت کاور حذف شد'
                    });

                    this.$store.dispatch('setUserData', {
                        forced: true
                    });
                    return 0;
                }
            });
        },
        // این متد برای ساختن لینک نمایش مناسب کاور کاربر در هدر استفاده میشود
        showAvatar() {
            let url = undefined;

            if (this.$route.params.username && this.$helpers.isJson(this.profileUser.avatar))
                url = this.$helpers.api_url() + this.$helpers.json_pars(this.profileUser.avatar)['name'];

            if (!this.$route.params.username && this.$helpers.userInfo().avatar)
                url = this.$helpers.api_url() + this.$helpers.userInfo().avatar.name;

            if (url == undefined) {
                this.avatarExist = false;
            } else {
                this.avatarExist = true;
            }

            // if (url.indexOf('undefined') > -1)
            // 	url = 'https://static.tgju.org/views/default/images/tgju-user-avatar.png';

            return `background-image: url("${ !url ? 'https://static.tgju.org/views/default/images/tgju-user-avatar.png' : url }"); background-position: center;background-size: cover;`;
        },
        // پاک کردن عکس اواتار
        removeAvatar() {
            let api_data = {
                action: 'delete',
            };

            this.$helpers.makeRequest('POST', '/user/avatar-upload', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 400) {
                    Vue.prototype.$toast.error({
                        title: 'خطا',
                        message: 'خطایی رخ داده است'
                    });
                    return 0;
                }

                if (api_response.status == 200) {
                    Vue.prototype.$toast.success({
                        title: 'موفق',
                        message: 'با موفقیت آواتار حذف شد'
                    });

                    this.$store.dispatch('setUserData', {
                        forced: true
                    });
                    return 0;
                }
            });
        },
        // مودال راهنما
        open_help_modal() {
            let modal_data = {
                modal: 'widget_help_modal',
                component: 'Helps/Profile.vue',
                title: 'پروفایل',
                show: true,
            };
            this.$root.$emit('help_modal', modal_data);
        },
    },
    computed: {
        // مشخص کردن هدر پروفایل خودمان از کاربران دیگر
        isMyProfile() {
            return this.$store.state.isMyProfile;
        }
    },
    watch: {
        // آپدیت هدر با توجه به تغییر کردن روت
        $route(to, from) {
            this.syncCurrentRout();

            if (this.$route.params.username) {

                this.getUser(this.$route.params.username);
                if (this.$helpers.userAuth()) {
                    this.isFriend(this.$route.params.username);
                }
            }
            this.headerDetail();
        },
    },
}
</script>
