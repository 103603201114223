<template>
<div>
    <!-- مودال راهنما -->
    <transition name="slide-fade">
        <div v-if="show" class="sidebarModal">
            <div class="sidebarModal-close" @click="closeModal()"><i class="uil uil-multiply pointer"></i></div>
            <div class="sidebarModal-header">
                <h3>
                    راهنما : {{title}}
                </h3>
                <p>
                    آموزش و راهنمای بخش {{title}} در پنل کاربری
                </p>
            </div>
            <div class="sidebarModal-inner">
                <component v-bind:is="_component" :data="helpModal.data"></component>
            </div>
            <i class="uil uil-question-circle sidebarModal-icon"></i>
        </div>
    </transition>
    <div v-if="show" class="sidebarModal-bg" @click="closeModal()"></div>
</div>
</template>

<script>
// این ویو برای اکثر مودال های عمومی سایت استفاده میشود
export default {
    name: 'Helps',
    components: {},
    data: function () {
        return {
            helpModal: {
                title: '',
                icon: '',
                smallModal: false,
                attributes: {},
                data: {},
            },
            component: null,
            modalClass: '',
            callbackEvent: null,
            show: false,
            title: ''
        }
    },
    computed: {
        _component() {
            if (!this.component) return null;
            return () => import(`@/components/Modals/${this.component}`);
        }
    },
    mounted() {
        this.$root.$on('help_modal', (data) => {
            this.show = data.show;
            this.component = data.component;
            this.title = data.title;
        });
    },
    methods: {
        closeModal() {
            this.show = !this.show
            if(this.show == false){
                this.$helpers.setCookie('ShowHelp',true,'30');
            }
        },
    },
}
</script>
