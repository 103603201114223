// web | mobile | desktop
window.AppLayout = 'web';

export default {

    navigatorClient(test) {
        var userAgent = navigator.userAgent;
        var dataClient = 'web';
        var dataClientVersion = '';
        var dataClientAgent = '';
        if (userAgent.split(' ').length && userAgent.indexOf('tgju') !== -1) {
            var us = userAgent.split(' ').reverse()[0].split('/');
            if (us.length == 2) {
                dataClientVersion = us[1];
            }
            dataClientAgent = us.join('/');
        }
        dataClient = 'web';
        if (typeof(navigator) !== 'undefined' && 'standalone' in navigator && navigator.standalone && (/iphone|ipod|ipad/gi).test(navigator.platform) && (/Safari/i).test(navigator.appVersion)) {
            dataClient = 'ios-standalone';
        } else if (userAgent.indexOf('tgju-ios') !== -1) {
            dataClient = 'ios';
        } else if (userAgent.indexOf('tgju-android') !== -1 || userAgent.indexOf('TGJUAndroid') !== -1) {
            dataClient = 'android';
        } else if (userAgent.indexOf('tgju-macos') !== -1) {
            dataClient = 'macos';
        } else if (userAgent.indexOf('com.vandaw.tgju') !== -1 && userAgent.indexOf('Windows') !== -1 && userAgent.indexOf('Electron') !== -1) {
            dataClient = 'windows';
        } else if (userAgent.indexOf('com.vandaw.tgju') !== -1 && userAgent.indexOf('Linux') !== -1 && userAgent.indexOf('Electron') !== -1) {
            dataClient = 'linux';
        }
        
        return test ? test === dataClient : {dataClient: dataClient, dataClientVersion: dataClientVersion, dataClientAgent: dataClientAgent};
    },

    checkDevice(name){
        if(process.env.VUE_APP_ENV == 'local') {
            if(window.AppLayout !== 'web') {
                if(window.AppLayout == name) {
                    return true;
                }
                if(name == 'app') {
                    return true;
                }
                return false;
            }
        }
        if(name == "app"){
            if (
                this.navigatorClient().dataClient == "ios" ||
                this.navigatorClient().dataClient == "ios-standalone" ||
                this.navigatorClient().dataClient == "android" ||
                this.navigatorClient().dataClient == "macos" ||
                this.navigatorClient().dataClient == "windows" ||
                this.navigatorClient().dataClient == "linux"
            ) {
                return true;
            }else{
                return false;
            }
        }else if(name == "mobile"){
            if (
                this.navigatorClient().dataClient == "ios" ||
                this.navigatorClient().dataClient == "ios-standalone" ||
                this.navigatorClient().dataClient == "android"
            ) {
                return true;
            }else{
                return false;
            }
        }else if(name == "desktop"){
            if (
                this.navigatorClient().dataClient == "macos" ||
                this.navigatorClient().dataClient == "windows" ||
                this.navigatorClient().dataClient == "linux"
            ) {
                return true;
            }else{
                return false;
            }
        }else if(name == "web"){
            if (
                this.navigatorClient().dataClient == "web"
            ) {
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    }
}